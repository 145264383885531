import * as React from 'react'

import useSound from 'use-sound'

import { DownloadIcon } from '@heroicons/react/outline'

// @ts-ignore
import hoverSfx from '../../static/sounds/ESM_POP_Game_Organic_Cartoon.wav'

const DownloadCVButton = () => {

  const [hoverSound] = useSound(hoverSfx)

  return (
    <button 
      onMouseEnter={() => hoverSound()} 
      onMouseLeave={() => hoverSound()} 
      className="flex gap-x-2 bg-secondary border border-4 border-tertiary text-accent px-4 py-2 rounded-md font-bold"
    >
      Download CV
      <DownloadIcon className="w-5 h-5" />
    </button>
  )
}

export default DownloadCVButton
