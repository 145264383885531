import * as React from 'react'

import Layout from '../components/core/Layout'
import DownloadCVButton from '../components/buttons/DownloadCVButton'

const About = () => {
  return (
    <Layout aurora>
      <div className="flex flex-col mx-6 mt-12 gap-y-6">
        <h1 className="font-bold text-primary text-5xl mb-4">About</h1>

        <div className="flex flex-col gap-y-6">
          <p>
            I’m a <strong>Fullstack Engineer</strong> at{' '}
            <strong>Behaviour Lab</strong> and lead the front-end team there. At
            Behaviour Lab, we use behavioural science and advanced analytics to
            transform how the £70 trillion active asset management industry make
            their investments. My focus is on data driven information
            visualization and user experience with creative coding, math, and
            engineering.
          </p>
          <p>
            I studied <strong>Electrical & Electronic Engineering</strong> at{' '}
            <strong>Imperial College London</strong> and graduated on the Deans
            List (top 10% academicaly) with First Class Honours. I was also the
            recepient of the Dennis Gabor Prize for best overall community to
            the department.
          </p>

          <p>
            During my time there, I built wireless autonomous supermarket
            checkout systems, simulations of self-organising multi-agent
            systems, and probabilistic inference modules to detect COVID from
            patient blood pathologies for the NHS.{' '}
          </p>

          <p>
            For my dissertation I built a visual search engine to explore 100
            million research publications through a novel search representation
            inspired by bibliometric mapping using <strong>D3.js</strong>,{' '}
            <strong>React</strong>, <strong>Express</strong>, and{' '}
            <strong>Elasticsearch</strong>.{' '}
          </p>

          <p>
            While search engines are ubiquitous in academic research and modern
            internet culture, most current digital search applications only
            parse information into large blocks of text. However, it is widely
            understood that one can improve understanding, retention, and
            insights by accompanying textual information with its graphic
            representation.{' '}
          </p>

          <p>
            <strong>VRSE (Visual Research Search Engine)</strong> was developed
            and tested as a prototype visual search engine that leverages
            quantitative techniques to visually encode publication metadata in
            search results from a database built with approximately 25 million
            publications.{' '}
          </p>

          <p>
            This prototype represents publications as nodes in an animated and
            interactive force-directed graph that uses colour to represent
            publication years. The novel visual representation in VRSE satisfies
            the design guidelines for fluid interaction, reduces Norman's Gulfs
            of Execution and Evaluation, and makes the time distribution of
            results distinguishable at a glance.
          </p>
        </div>
      </div>

      <div className="max-w-3xl mx-auto px-4 mt-12">
        <div className="flex gap-x-6">
          <DownloadCVButton />
        </div>
      </div>
    </Layout>
  )
}

const EducationItem = ({
  title,
  subTitle,
  description,
}: {
  title: string
  subTitle: string
  description: string
}) => {
  return (
    <div className="my-6">
      <h4 className="text-blue-500 font-bold">{title}</h4>
      <p className="font-light antialiased">{subTitle}</p>
      <p>{description}</p>
    </div>
  )
}

export default About
